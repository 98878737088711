/**
 * Created by esabbath on 2/19/16.
 */
$(function()
{
    if ($("div[data-content=ws-review]").length)
    {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $(".rbtn").bind('click', Keyword.vote);

        if ($("#ad_id").length) {
            Keyword.setScoreableType('ad');
        }
        else if ($("#website_id").length) {
            Keyword.setScoreableType('website');
        }
        else if ($("#app_id").length) {
            Keyword.setScoreableType('app');
        }
        else if ($("#fad_id").length) {
            Keyword.setScoreableType('fad');
        }
        else if ($("#social_id").length) {
            Keyword.setScoreableType('social');
        }
        else if ($("#sig_id").length) {
            Keyword.setScoreableType('sig');
        }

        //$(".btn-reply").bind('click', Comment.reply);
        $("div[data-content=comments]").on('click', 'a.btn-reply', Comment.reply);
        $("div[data-content=comments]").on('click', 'a[role="btn-delete"]', Comment.destroy);
        $("div[data-content=comments]").on('click', 'a.rcbtn', Comment.vote);
        $("div[data-content=comments]").on('click', 'a.btn-report', Comment.report);

        /*ban user from sig*/
        $("div[data-content=comments]").on('click', 'a[role="btn-ban"]', User.ban);

        /**/
        $('a[data-action="keywords-toggle"]').on('click', Keyword.toggleList);

        /*Share cintent with a user*/
        $('select[data-action="user-share"]').on('change', User.share);
        $('a[data-action="user-share"]').on('click', function(e){
            e.preventDefault();
            $(this).hide();
            $('div.searchable').show();
            Searchable.resetResults();
        });



        Comment.init();

    }

    User.initSelector();



    if($("div[data-content=ws-review-list]").length)
    {

        /*paginator*/
        $("div[data-content=ws-review-list]").on('click', 'ul.pagination a', function (e) {
            //console.log($(this));
            e.preventDefault();
            $.get($(this).attr('href'), {}, function (data) {
                //console.log(data);
                if (data && data.success) {
                    $('html,body').animate({
                        scrollTop: $("div[data-content=ws-review-list]").offset().top
                    }, 300);
                    $("#content-list").html(data.html);
                }
            }, 'json');
        });

        window.reviewSearchResult = function(response)
        {
            if (response && response.success) {
                $("#content-list").html(response.html);
            }
        }
    }

    if($("div[data-content=bookmarks-list]").length)
    {
        $("form[role='filter-saved-results']>*").on('change', function(e) {
            $(this).parents('form').submit();
        });

        $('div#content-list').on('click', 'a[role="category-edit"]', function (e) {
            e.preventDefault();
            var sel = $('#category-selector').html();
            var $el = $(this).parent().find('span[role="bcategory"]');
            var $sel = $(sel)
            $sel.val($el.data('id'));

            // console.log($sel, $el.data('id'));


            $el.html(sel);
            $(this).hide();
        });

        $('div#content-list').on('click', 'button[type="submit"]', function (e) {
            $(this).parents('div.ads-wrapper').hide();
        });

    }

    if ($("div[data-content=user-info]").length)
    {
        /*follow user*/
        $("div.userDetail").on('click', 'a[data-action="user-follow"]', User.follow);
    }

    window.reviewListResult = function(response)
    {
        if (response && response.success) {
            $("#content-list").html(response.html);
        }
    }


    $('a[role="close-zb-popup"]').on('click', closePopUp);
});

function closePopUp()
{
    var $el = $('#zb-popup-report.active');
    $el.removeClass('active');
    $("body").off('click');
    clearTimeout(popTo);
}
var popTo;
function openPopUp()
{
    var $el = $('#zb-popup-report');
    $el.css("top", Math.max(0, (($(window).height() - $el.outerHeight()) / 2) +
            $(window).scrollTop()) + "px");
    $el.css("left", Math.max(0, (($(window).width() - $el.outerWidth()) / 2) +
            $(window).scrollLeft()) + "px");
    $el.addClass('active');

    popTo = setTimeout(closePopUp, 4000);

    setTimeout(function(){
        $("body").on('click', function(){
            closePopUp();
        });
    }, 10);


    // Prevent events from getting pass $el
    $el.click(function(e){
        e.stopPropagation();
    });
}

Keyword = {
    _uri: '/website-review/rate/keyword',
    _scoreableType: '',
    _scoreableId: 0,
    setScoreableType: function(scoreableType)
    {
        Keyword._scoreableType = scoreableType;
        Keyword._scoreableId = parseInt($("#" + scoreableType + "_id").val(), 10);
    },
    vote: function()
    {
        var kwId = $(this).data('id'),
            score = $(this).data('value'),
            data = {
                scoreable_id: Keyword._scoreableId,
                scoreable_type: Keyword._scoreableType,
                id: kwId,
                score: score
            };

        $.post(Keyword._uri, data, function (result) {
            //console.log(result);
            if (result && result.id) {
                $("span[data-type=score][data-id=" + result.id +"][data-score=p]").text(result.data.p);
                $("span[data-type=score][data-id=" + result.id +"][data-score=n]").text(result.data.n);
                $("span[data-type=score][data-id=" + result.id +"][data-score=m]").text(result.data.m);
            }
        }, "json")
            .fail(function(jqXhr) {
                if (401 == jqXhr.status) {
                    window.location = '/auth/login';
                    return;
                }
            });
    },

    toggleList: function()
    {
        $('div[data-content="keywords"]').toggle();
        $(this).children().toggleClass('fa-chevron-down');
        $(this).children().toggleClass('fa-chevron-up');
    }
};

var activeUsers = {};

Comment = {
    _vote_uri: '/website-review/rate/comment',
    _destroy_uri: '/website-review/comments/',
    _report_uri: '/website-review/comments/report/',
    _deleted_message: 'Comment deleted by user',
    _banned_message: 'Comment removed by moderator',
    init: function()
    {
        if (!$('#message').length) {
            return false;
        }


        activeUsers = JSON.parse($('script[data-content="active-users"]').html());
        document.emojiSource = '/images/emoji';
        //document.emojiType = 'unicode'; // default: image
        $('#message').summernote({
            placeholder: $('#message').attr('placeholder'),
            tabsize: 2,
            height: 210,
            toolbar: [
                // [groupName, [list of button]]
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['font', ['strikethrough', 'superscript', 'subscript']],
                ['color', ['color']],
                ['para', ['ul', 'ol']],
                ['insert', ['link', 'picture', 'video', 'emoji']],
            ],
            hint: {
//                mentions: activeUsers,
                match: /\B@(\w*)$/,
                users: function(keyword, callback)
                {
                    if (keyword.length <= 3) {
                        keyword = keyword.toLowerCase();
                        callback($.grep(activeUsers, function (item) {
                            return item.toLowerCase().indexOf(keyword) == 0;
                        }));
                        // callback(activeUsers);
                    }
                    else {
                        $.ajax({
                            url: '/website-review/users?v=2&query=' + keyword,
                            type: 'get',
                            async: true //This works but freezes the UI
                        }).done(callback);
                    }

                },
                search: function (keyword, callback) {
                    this.users(keyword, callback); //callback must be an array
                },
                content: function (item) {
                    return '@' + item;
                }
            },
            maximumImageFileSize: 16777216
        });

        //add users search field to wysiwig
        console.log($('div.note-hint-popover'));
        // $('div.note-hint-popover').prepend( '<input type="text" placeholder="select user" onkeyup="Comment.searchUser(this)">' );

        //scroll to comment
        var hash = location.hash.substr(1);
        var commentId = getUrlParameter('com')
        if (commentId)
        {
            var comEl = $('div[data-content="comments"]').find('div[data-id=' + commentId + ']');
            if (comEl.length)
            {

                if (3 == $('div.tab-pane').length) {
                    $('div.tab-pane').removeClass('active');
                    $('div.tab-pane#2').addClass('active');
                    $('ul.nav-tabs').find('li.active').removeClass('active');
                    $('a[href="#2"]').parents('li').addClass('active');
                }

                comEl[0].scrollIntoView();
            }


        }

    },
    reply: function()
    {
        var parentId = $(this).data('id'),
            $parentDiv  = $(this).parent().parent().parent("div[data-content=comment]");
        var $form = $("#form-comment"),
            $formDiv  = $form.parent('div');

        $("#parent_id", $form).val(parentId);
        $parentDiv.append($formDiv);
    },
    response: function(data)
    {
        //console.log(data);
        var $form = $("#form-comment"),
            $formDiv  = $form.parent('div');

        $("#parent_id", $form).val('');
        $("#message", $form).val('');
        //$('#message').summernote('reset');
        $('#message').summernote('code', '');

        if (data.status && 'success' == data.status)
        {
            tmpl.encReg = /(^\s+)|(\s+$)|['\x00]/g;
            var commentHtml = tmpl('comment-result', data.comment);

            if (data.comment.parent_id) {
                $("div[data-content='comment'][data-id='" + data.comment.parent_id +"']").after(commentHtml);
                $("#form-comment-container").append($formDiv);
            }
            else {
                $("div[data-content=comments]").append(commentHtml);
            }
        }
    },
    vote: function()
    {
        var $parentDiv  = $(this).parent().parent().parent("div[data-content=comment]"),
            commentId   = $parentDiv.data('id'),
            score       = $(this).data('value'),
            data = {
                id: commentId,
                score: score
            };
        //console.log(data);
        $.post(Comment._vote_uri, data, function (result)
        {
            //console.log(result);
            if (result && result.id) {
                $("span[data-type=score][data-score=p]", $parentDiv).text(result.data.p);
                $("span[data-type=score][data-score=n]", $parentDiv).text(result.data.n);
                $("span[data-type=score][data-score=m]", $parentDiv).text(result.data.m);
            }
        })
        .fail(function(jqXhr) {
            if (401 == jqXhr.status) {
                window.location = '/auth/login';
                return;
            }
        });
    }
    ,
    destroy: function()
    {
        var id = $(this).data('id'),
            data = {
                id: id,
                _method: 'DELETE'
            };
        $.post(Comment._destroy_uri + id, data, function (result)
        {
            //console.log(result);
            if (result && result.id)
            {
                var $comment = $("div[data-content='comment'][data-id='" + result.id + "']");
                if ('block' == result.action) {
                    $comment.children('div[data-content="message"]').html(Comment._deleted_message);
                    $comment.children('div.comment-btn-group').hide();
                }
                if ('ban' == result.action) {
                    $comment.children('div[data-content="message"]').html(Comment._banned_message);
                    $comment.children('div.comment-btn-group').hide();
                }
                if ('delete' == result.action) {
                    $comment.hide();
                }
            }
        });
    },
    report: function()
    {
        var $parentDiv  = $(this).parents("div[data-content=comment]"),
            commentId   = $parentDiv.data('id'),
            data = {
                id: commentId,
            };

        $(this).hide();
        $(this).parent().find('i.fa-flag').hide();
        $(this).parent().find('div.pointSep').first().hide();

        console.log('openPopUp()');
        openPopUp();

        $.post(Comment._report_uri, data, function (result) {
                //console.log(result);
                if (result && result.id) {
                    var $comment = $("div[data-content='comment'][data-id='" + result.id + "']");
                    if ('block' == result.action) {
                        $comment.children('div[data-content="message"]').html(Comment._deleted_message);
                        $comment.children('div.comment-btn-group').hide();
                    }
                    if ('delete' == result.action) {
                        $comment.hide();
                    }
                }
            })
            .fail(function(jqXhr) {
                if (401 == jqXhr.status) {
                    window.location = '/auth/login';
                    return;
                }
            });
    },
    searchUser: function(el)
    {
        var input_val = $(el).val().toLowerCase();
        if (input_val.length > 2) {
            User.search(input_val, Comment.showUsersResult);
        }
    },
    showUsersResult: function (data) {
        console.log(data);
        //note-hint-group

        var $hint = $('div.note-hint-popover');
        var $listContainer = $hint.find('div.note-hint-group');
        var liList = '';
        for (var i in data) {
            console.log(i, data[i]);
            var li ='<div class="note-hint-item" data-id="' + i + '">' + data[i] + '</div>';
            liList += li;
        }
        $listContainer.html(liList);
        $listContainer.find('div').first().addClass('active');
        //$('#message').summernote({hint: {mentions: data}});

        activeUsers = data;
        activeUsers = Array.from(data);

        console.log(typeof data);
        // console.log($('#message').summernote('HintPopover.hint', data));
        // console.log($('#message').summernote('hintPopover.createItemTemplates', 0, Array.from(data)));
        // console.log($('#message').summernote('HintPopover'));
        // console.log($('#message').summernote('hintPopover'));

    }
};

function commentResponse(data) {
    Comment.response(data);
}

Category = {
    select: function(ptr)
    {
        // console.log($(ptr));
        var $ptr = $(ptr);
        var catId = $ptr.val();
        var catName = $ptr.find("option:selected").text();
        var $el = $ptr.parent();
        var $btn = $el.parent().find('a[role="category-edit"]');
        var bookmarkId = $el.parents('div.ads-wrapper').data('id');

        $btn.show();
        $el.html(catName);
        $el.data('id', catId);
        var data = {
            bookmark: bookmarkId,
            category: catId
        };
        // console.log(data);
        $.post('/website-review/bookmarks/set-category', data);
    }
}

User = {
    ban: function () {
        var $el  = $(this),
        userId = $el.data('id');

        var data = {
            'user_id': userId,
            'sig_id': $("#sig_id").val(),
            'status': $el.data('status')
        };
        //console.log(data);
        $.post('/website-review/users/ban', data, function(data)
        {
            //console.log(data);
            if (data && data.success)
            {
                var status = data.status;
                var opStatus = 1 == status ? 0 : 1;

                $('a[role="btn-ban"][data-status="' + status + '"]').hide();
                $('a[role="btn-ban"][data-status="' + opStatus + '"]').show();
            }
        }, 'json');
    },
    follow: function ()
    {
        var $el  = $(this),
            userId = $el.parents('div.userDetail').data('id');

        var data = {
            'user_id': userId,
            // _method: 'CREATE',
            'status': $el.data('status'),
            _token: $('meta[name="csrf-token"]').attr('content')
        };

        $.post('/user/follows', data, function(data)
        {
            if (data && data.success)
            {
                var status = data.status;
                var opStatus = 1 == status ? 0 : 1;

                $('a[data-action="user-follow"][data-status="' + status + '"]').hide();
                $('a[data-action="user-follow"][data-status="' + opStatus + '"]').show();
            }
        }, 'json')
        .fail(function(jqXhr) {
            if (401 == jqXhr.status) {
                window.location = '/auth/login';
                return;
            }
        });
    },
    share: function (userId)
    {
        var data = {
            'user_id': userId,
            'notificationable_id': $('#commentable_id').val(),
            'notificationable_type': $('#commentable_type').val(),
            _token: $('meta[name="csrf-token"]').attr('content')
        };
        // console.log(data);

        $.post('/user/notifications', data, function(data)
        {
            if (data && data.success)
            {
                // console.log(data);
                $('div.searchable').hide();
                $('a[data-action="user-share"]').show();
                var buttonText = $('a[data-action="user-share"]').html();
                $('a[data-action="user-share"]').html('Shared');
                $('a[data-action="user-share"]').delay(1000).queue(function(n) {
                    $(this).html(buttonText); n();
                });
            }
        }, 'json')
            .fail(function(jqXhr) {
                if (401 == jqXhr.status) {
                    window.location = '/auth/login';
                    return;
                }
            });

    },
    initSelector: function()
    {
        Searchable.init();
    },
    search: function (query, callback)
    {
        // console.log(query);
        $.get('/website-review/users', {'query': query}, function(data) {
            if (data && data.success && data.users) {
                callback(data.users);
            }
            // console.log(data);
        }, 'json');
    }
}
//search in list

Searchable = {
    _container: '',
    _initList: '',
    init: function()
    {
        Searchable._container = $("div.searchable");
        var ul = Searchable._container.find("ul");
        Searchable._initList = ul.html();
    },
    showResults: function(data)
    {
        var ul = Searchable._container.find("ul");
        var liList = '';
        for (var i in data) {
            // console.log(i, data[i]);
            var li ='<li data-id="' + i + '">' + data[i] + '</li>';
            liList += li;
        }
        ul.html(liList);
        Searchable._container.find("ul li:visible").first().addClass("selected");
    },
    resetResults: function()
    {
        var ul = Searchable._container.find("ul");
        ul.html(Searchable._initList);
        Searchable._container.find("input").val('');
    }
}

function filterFunction(that, event) {
    let container, input, filter, li, input_val;
    container = $(that).closest(".searchable");
    input_val = container.find("input").val().toLowerCase();

    if (["ArrowDown", "ArrowUp", "Enter"].indexOf(event.key) != -1) {
        keyControl(event, container)
    }
    else
    {
        //search user
        li = container.find("ul li");
        if (input_val.length > 2) {
            User.search(input_val, Searchable.showResults);
        }
        if (0 == input_val.length) {
            Searchable.resetResults();
        }

        container.find("ul li").removeClass("selected");
        setTimeout(function () {
            container.find("ul li:visible").first().addClass("selected");
        }, 100)
    }
}


function keyControl(e, container) {
    if (e.key == "ArrowDown") {

        if (container.find("ul li").hasClass("selected")) {
            if (container.find("ul li:visible").index(container.find("ul li.selected")) + 1 < container.find("ul li:visible").length) {
                container.find("ul li.selected").removeClass("selected").nextAll().not('[style*="display: none"]').first().addClass("selected");
            }

        } else {
            container.find("ul li:first-child").addClass("selected");
        }

    } else if (e.key == "ArrowUp") {

        if (container.find("ul li:visible").index(container.find("ul li.selected")) > 0) {
            container.find("ul li.selected").removeClass("selected").prevAll().not('[style*="display: none"]').first().addClass("selected");
        }
    } else if (e.key == "Enter") {
        container.find("input").val(container.find("ul li.selected").text()).blur();
        onSelect(container.find("ul li.selected"))
    }

    container.find("ul li.selected")[0].scrollIntoView({
        behavior: "smooth",
    });
}

function onSelect(el) {
    var userId = el.data('id');
//    alert(userId);
    if ('invite-user' == el.parent().data('role')) {
        Chat.invite(userId);
    }
    else if ($("div[data-content=chat]").length) {
        Chat.create(userId);
    }
    else {
        User.share(userId);
    }

}

$(".searchable input").focus(function () {
    $(this).closest(".searchable").find("ul").show();
    $(this).closest(".searchable").find("ul li").show();
});
$(".searchable input").blur(function () {
    let that = this;
    setTimeout(function () {
        $(that).closest(".searchable").find("ul").hide();
    }, 300);
});

$(document).on('click', '.searchable ul li', function () {
    $(this).closest(".searchable").find("input").val($(this).text()).blur();
    onSelect($(this))
});

$(".searchable ul li").hover(function () {
    $(this).closest(".searchable").find("ul li.selected").removeClass("selected");
    $(this).addClass("selected");
});